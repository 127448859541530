import React from "react"

import { css } from '@emotion/react'

const Video = ({ url, title, ...props }) => {
    function prepUrl(fragment) {
            if ( fragment.match(/embed/) ) return fragment
            if ( fragment.match(/watch/) ) {
                let uid = fragment.split(/v=/)[1]
                let embedUrl = "https://www.youtube.com/embed/" + uid
                console.log(embedUrl)
                return embedUrl
            }
            else return null
    }

    return (
        <div css={container}>
            <iframe
            src={prepUrl(url)}
            title={title}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            />
        </div>
    )
}

export default Video

const container = css`
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; 
    height: 0; 
    overflow: hidden;

    iframe, object, embed { 
        position: absolute; 
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 100%; 
    }
`


